<template>
  <b-modal v-if="article" id="upload-articles-modal" size="lg" :title="`Edit Article`" v-model="showModal">
    <b-overlay :show="loading" rounded="sm">
      <form>
        <h4>{{ article.latina_article_id}}: {{ article.title }} /
          {{ article.is_gift_box === 0 ? 'NGB' : 'GB' }} /
          {{ article.alcohol_percentage}}% /
          {{ article.content_count }} {{ article.content_unit}} /
          {{ article.inner_content_count }} {{ article.inner_content_unit}}
        </h4>
        <p>You can edit certain information about this article.</p>
        <b-alert variant="danger" :show="this.errors.length > 0">
          <ul>
            <li v-for="error in this.errors" :key="error">{{ error }}</li>
          </ul>
        </b-alert>

        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="file_upload">Sale Price Type</label>
            </div>
            <div>
              <div class="formElement__input">
                <v-select name="price_type_select" id="supplier-price_type_select" :placeholder="`Please select`"
                          class="wide" v-model="article.sale_price_type"
                          :reduce="option => option.value"
                          :options="this.price_types"></v-select>
              </div>
              <div class="help-text">
                Select between Automatic or manual price calculation. Manually Priced Articles must have an owner
              </div>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="file_upload">Show on Website?</label>
            </div>
            <div>
              <div class="formElement__input">
                <v-select name="visible_on_website_select" id="visible_on_website_select" :placeholder="`Please select`"
                          class="wide"
                          v-model="article.show_on_website"
                          :reduce="option => option.value"
                          :options="[{
                            label: 'Yes',
                            value: 1
                          },
                          {
                            label: 'No',
                            value: 0
                          }]"></v-select>
              </div>
              <div class="help-text">
                Select between Automatic or manual Price
              </div>
            </div>
          </div>
        </div>
        <div class="formRow" v-if="isManual">
          <div class="formElement">
            <div class="formElement__label">
              <label for="file_upload">Owner</label>
            </div>
            <div>
              <div class="formElement__input">
                <v-select name="visible_on_website_select" id="visible_on_website_select"
                          :placeholder="`Please select`"
                          class="wide"
                          v-model="article.owner_user_id"
                          :reduce="option => option.value"
                          :options="users"></v-select>
              </div>
              <div class="help-text">
                Manually priced articles must specify an owner
              </div>
            </div>
          </div>
        </div>
        <div class="formRow" v-if="isManual">
          <div class="formElement">
            <div class="formElement__label">
              <label for="file_upload">Sale Price (€)</label>
            </div>
            <div>
              <div class="formElement__input">
                  <b-form-input name="article-price-unit" v-model="article.sale_price" type="number" placeholder="0.00"/>
              </div>
              <div class="help-text">
                Manually priced articles must specify a fixed Sale price
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="success" @click="save">
        Save
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>
export default {
  name: "EditArticleModal",
  data() {
    return {
      errors: [],
      price_types: [
        {
          label: 'Automatic',
          value: 'A'
        },
        {
          label: 'Manual',
          value: 'M'
        }
      ]
    }
  },
  beforeMount() {
    this.$store.dispatch('users/fetchUsers');
  },
  components: {},
  computed: {
    loading: {
      get() {
        return this.$store.state.articles.isLoading
      }
    },
    isManual: {
      get() {
        return (this.article.sale_price_type === 'M')
      }
    },
    users: {
      get() {
        return this.$store.state.users.users.map(user => {
          return {
            label: user.name,
            value: user.id
          }
        })
      }
    },
    showModal: {
      get() {
        return this.$store.state.articles.modals.isEditArticleModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('articles/openEditArticleModal');
        } else {
          this.$store.dispatch('articles/closeEditArticleModal');
        }
      }
    },
    article: {
      get() {
        return this.$store.state.articles.currentArticle
      }
    }
  },
  methods: {
    validate() {
      this.errors = [];
      if (this.isManual && !this.article.owner_user_id) {
        this.errors.push('Owner is required for manually priced articles');
      }
      if (this.isManual && !this.article.sale_price) {
        this.errors.push('Sale price is required for manually priced articles');
      }

      return this.errors.length === 0;
    },
    reset() {
      this.$store.dispatch('articles/closeEditArticleModal');
    },
    async save() {
      if (!this.validate()) {
        return;
      }
      await this.$store.dispatch('articles/updateArticle', this.article);
    }
  }

}
</script>

